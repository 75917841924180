import * as React from 'react'

const TestPage = () => {
  return (
    <div>
      <h1 className='p-5 text-xl'>HELLO THIS IS A TEST</h1>
      <a href='/'>go home</a>
    </div>
  )
}

export default TestPage
